.dor-live-case {
  .execution-flow-footer {
    width: 100%;
    height: 54px;
    position: fixed;
    bottom: 0;
    padding: 0;
    background-color: white;
    justify-content: center;

    .ant-btn {
      width: 90px;
      margin: 12px;
    }
  }

  .main-step-col {
    background-color: #000e1a;
    overflow: scroll;

    .main-step-content {
      padding: 35px;
      height: 85vh;
      overflow: scroll;
      width: 100%;
    }
  }

  .equipment-image {
    border: 1px solid #fff;
    height: 8rem;
    background-color: #fff;
    border-radius: 3px;

    .ant-image {
      height: 100%;
      cursor: pointer;
      object-fit: cover;

      img {
        object-fit: contain;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  .preview-step-col-1 {
    background-color: #f2f2f2;
    .preview-step-col-2 {
      padding: 16px;
      background-color: white;
      min-height: 24rem;
      max-height: 30rem;
      overflow: scroll;
    }
  }

  .live-case-surgical-step {
    height: 100%;
    // .equipment-image {
    //   border: 1px solid #fff;
    //   height: 11rem;
    //   background-color: #fff;
    //   border-radius: 3px;

    //   .ant-image {
    //     height: 100%;
    //     cursor: pointer;
    //     object-fit: cover;

    //     img {
    //       object-fit: contain;
    //       height: 100%;
    //       border-radius: 3px;
    //     }
    //   }
    // }
    .ant-list-item {
      margin-bottom: 22px !important;
    }
  }

  .check-box-new span {
    display: inline-block;
    padding: 6px 18px;
    background-color: #cccccc;
    color: black;
    position: absolute;
  }

  .check-box-new span:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 18px solid #cccccc;
    border-bottom: 18px solid transparent;
    position: absolute;
    right: -18px;
    top: 0;
  }

  .check-box-new-preview span {
    display: inline-block;
    padding: 6px 18px;
    background-color: green;
    color: white;
    position: absolute;
  }

  .check-box-new-preview span:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 18px solid green;
    border-bottom: 18px solid transparent;
    position: absolute;
    right: -18px;
    top: 0;
  }

  .checklist-checkbox {
    .ant-checkbox-inner {
      background-color: transparent;
      height: 20px;
      width: 20px;
    }

    .ant-checkbox + span {
      color: white;
    }

    .ant-checkbox.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: green;
        border: 0;
      }
      .ant-checkbox-inner::after {
        width: 8px;
        height: 14px;
      }
    }
  }
}

.dor-all-equipments-drawer {
  .equipment-image {
    border: 1px solid rgb(222, 222, 222);
    height: 10rem;
    background-color: #fff;
    border-radius: 3px;

    .ant-image {
      height: 100%;
      cursor: pointer;
      object-fit: cover;

      img {
        object-fit: contain;
        height: 100%;
        border-radius: 3px;
      }
    }
  }

  .phase-name {
    padding: 2px 12px;
    background: #feefe8;
    border-radius: 4px;
    color: #f26524;
    width: max-content;
    margin-bottom: 8px;
  }

  .step-label {
    padding: 2px 12px;
    background: rgb(247, 247, 247);
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .tray-label {
    min-width: fit-content;
    padding: 1.5px 10px;
    border-radius: 4px;
    background: rgb(242, 242, 242);
    margin-bottom: 8px;
  }
}

.section-number {
  color: @theme-color-tangerine;
  background-color: @theme-color-white;
  border: 1px solid;
  height: 24px;
  width: 24px;
  justify-content: center;
  display: flex;
  margin-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.section-number-for-case-cart {
  margin-right: 5px;
  height: 24px;
  width: 24px;
  color: @theme-color-tangerine;
  background-color: @theme-color-white;
  display: inline-flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid;
  path {
    fill: @theme-color-tangerine;
  }
}
.main-step-content {
  .ant-btn {
    margin-right: 0 !important;
  }
}
.main-step-content::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}
/* Track */
.main-step-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.85);
}

/* Handle */
.main-step-content::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 12px;
}

/* Handle on hover */
.main-step-content::-webkit-scrollbar-thumb:hover {
  background: grey;
  border-radius: 12px;
}

.main-step-content::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

.preview-step-col-2::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}
/* Track */
.preview-step-col-2::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.preview-step-col-2::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 12px;
}

/* Handle on hover */
.preview-step-col-2::-webkit-scrollbar-thumb:hover {
  background: grey;
  border-radius: 12px;
}
