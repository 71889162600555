@import "./antd-theme.less";

.equipment-upload-img {
  .add-img-btn {
    background-color: rgb(254, 239, 232);
    border: none;
    color: rgb(242, 101, 36);
    border-radius: 4px;
  }

  .upload-item-box {
    border: solid 1px rgb(209, 209, 209);
    border-radius: 4px;
    height: 70px;
    margin: 8px 0;
    .ant-col {
      display: flex;
      align-items: center;
    }
  }

  .upload-img-fail-box {
    background-color: rgb(255, 255, 230);
    height: 53px;
    width: 53px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-list-video {
    position: absolute;
    height: 60px;
    width: 96px;
    opacity: 0.7;
    border-radius: 4px;
  }
}

.custom-tag-list-item {
  margin-top: 1.5rem;
  .ant-row {
    height: 4.2em;
    .ant-col {
      padding: 4px 0;
      .ant-tag {
        color: black;
        height: 100%;
        font-size: inherit;
      }
    }
  }
}

.equipments-tab {
  .ant-tabs-tab {
    padding: 12px 32px;
  }
}

.equipment-manufacturer-filter {
  button {
    padding: 4px 6px;
  }
  .ant-tag {
    margin: 0 6px 0 0;
    height: auto;
    border-radius: 12px;
  }
}
.manufacturer-filter-dropdown-menu {
  .ant-tag {
    margin: 0;
    height: auto;
  }

  .ant-tag-checkable {
    background-color: #fff;
    color: @theme-color-60p-black;
    border-color: @theme-color-60p-black;
  }

  .ant-tag-checkable.ant-tag-checkable-checked {
    background-color: @theme-color-light-pink;
    color: @theme-color-tangerine;
    border-color: @theme-color-tangerine;
  }
}
