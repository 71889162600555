@import "antd/lib/style/themes/default.less";
@import "antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "./styles/antd-theme.less";
@import "/node_modules/react-grid-layout/css/styles.css"; //Importing react grid layout styling
@import "/node_modules/react-resizable/css/styles.css"; //Importing react grid layout styling
@import "./styles/distributor-styles.less";
@import "./styles/cases-styles.less";
@import "./styles/live-case.less";

// General
a {
  color: @theme-color-black;

  :hover {
    color: @theme-color-60p-black;
  }
}

svg {
  flex-shrink: 0;
}

/** ANTD classes */
.ant-btn.ant-btn-secondary {
  border-color: @theme-color-60p-black;
}

.ant-page-header-heading-extra {
  display: flex;
}

.ant-input-search {
  width: 25em;
}

.ant-notification-notice-message {
  color: @theme-color-white;
}

.ant-spin-dot-item {
  background-color: @theme-color-tangerine;
}

.ant-tag-close-icon {
  display: flex;
  margin-left: auto;
}

.ant-upload.ant-upload-select-picture-card {
  width: 250px;
  height: 200px;
  margin: 0;
}

.ant-dropdown-menu-item:hover {
  background-color: @theme-color-light-pink;
}

.ant-select-dropdown {
  .rc-virtual-list {
    .rc-virtual-list-scrollbar {
      width: 3px !important;
    }
  }
}

.assignment-select-wrapper {
  position: relative;

  .ant-select-selector{
    padding-left: 11px !important;
    padding-right: 11px !important;

    .ant-select-selection-search {
      margin: 0 !important;
    }
  }

  .multi-select-placeholder {
    opacity: 1;
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    transform: translateY(-50%);
    overflow: hidden;
    color: #bfbfbf;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    padding-left: 2px;
  }
  
  .multi-select-placeholder--hide {
    opacity: 0;
  }
}

.ant-modal-title {
  color: @theme-color-black  !important;
}

.ant-modal-content {
  border-radius: 5px;
}

.ant-modal-confirm-title {
  color: @theme-color-tangerine  !important;
}

.ant-tag {
  height: 2rem;
  margin: 0 0 0.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ant-form-large .ant-form-item-label>label {
  height: 0;
}

.only-description-surgical-modal {
  .ant-modal-header {
    border-radius: 20px 20px 20px 20px;
  }
}

.tutorial-description-surgical-modal {
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
}

.ant-modal-body {
  overflow: scroll;
}

.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}

/** CUSTOM */
#dorNavSider {
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    border-right: 4px solid @theme-color-tangerine;
  }

  .ant-menu-item {
    padding: 8px !important;
    margin: 4px 0 !important;
  }
}

.ant-tag:not(.activation-link, .ant-tag-success) {
  >span:first-of-type {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

.ant-tag.activation-link {
  >span:nth-of-type(2) {
    width: 100%;
  }
}

.display-flex-center {
  display: flex;
  align-items: center;
}

.display-grid-center {
  display: grid;
  align-items: center;
}

.sixty-percent-black-text {
  color: @theme-color-60p-black;
}

.icon-overlay {
  padding-top: 2px;
  padding-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.icon-overlay-top-left {
  padding-top: 2px;
  padding-left: 2px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
}

.icon-overlay-container {
  position: relative;
  display: inline-block;
  height: inherit;
  border: 1px solid @theme-color-input-border;

  .ant-image-img {
    object-fit: contain;
  }
}

.procedure {
  .ant-tag {
    margin: 8px 9px;
    text-align: center;
    border: 1px solid;
    display: inline-flex;
    justify-content: center;
    padding: 12px;
  }
}

.mini-app-layout {
  margin-top: -4px;

  .ant-page-header {
    padding: 0;
  }

  .ant-page-header-heading {
    align-items: center;
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.08);
  }

  .ant-divider-horizontal {
    border: none;
  }

  .ant-page-header-content {
    padding: 0;
  }

  .ant-btn {
    // margin-top: 3px;
    margin-right: 20px;
  }

  .ant-tag-checkable-checked {
    background: @table-row-hover-bg;
    color: rgb(242, 101, 36);
  }

  .ant-page-header-back {
    font-size: 25px;
    background-color: @theme-color-light-pink;
    padding: 10px;
  }

  .ant-page-header-heading-left {
    margin-bottom: 0;
  }
}

.trayStyle {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.overlay-button {
  padding: 0;
  margin-right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.search-row {
  margin-top: 1.25rem;
  margin-bottom: 1.2rem;
  padding: 0;
}

.tray-equipment {
  overflow: hidden;

  .ant-btn {
    margin: 0 !important;
  }

  .ant-page-header-content {
    padding-top: 0 !important;
  }

  .ant-list-item {
    padding: 0;
  }
}

.tray-done-button {
  .ant-btn {
    margin-right: 3rem;
  }
}

.plus-button {
  border: 1px solid;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
}

.grid-layout-style {
  .grid-number {
    position: absolute;
    z-index: 2;
    color: @theme-color-tangerine;
    background-color: @theme-color-white;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    font-size: 1em;
    margin: 10px 7px;
    bottom: 0;
  }
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  border: 1px solid black;
}

.table-setup-layout {
  .ant-tag {
    color: @theme-color-tangerine;
    font-size: 1rem;
    padding: 0 20px;
  }

  .ant-tag-checkable-checked {
    color: black;
    background: transparent;
  }

  .center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .clear-button {
    border: 0;
    background-color: transparent;
    outline: 0 !important;
    cursor: pointer;
  }

  .tray-management-button {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @theme-color-tangerine;
    background-color: transparent;
    height: 100%;
    width: 14rem;
    font-size: 1rem;
  }
}

.table-setup-main-container {
  overflow: hidden;
  height: 100vh;

  .ant-page-header-heading-left {
    width: 100%;
    //height: 70px;
  }

  .ant-page-header-heading-tags {
    position: absolute;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    //left: 45%;
    width: 100%;
  }

  @media only screen and (max-width: 900px) {
    .ant-page-header-heading-tags {
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      z-index: 0;
      //left: 45%;
      display: grid;
      width: 100%;
      left: 10%;
    }
  }

  .ant-page-header-heading-sub-title {
    z-index: 2;
  }

  .ant-page-header-back {
    z-index: 2;
  }

  .ant-tag {
    font-size: 1rem;
  }

  .table-setup-tags {
    margin: 0;
    padding: 0 20px;
  }

  .ant-page-header-heading-title {
    max-width: 20%;
    margin-right: 0;
  }

  .ant-image {
    width: 100%;
    height: 100%;

    .ant-image-img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.container {
  margin-left: 100px;
  margin-top: 225px;
  text-align: left;
}

current-step span {
  color: gray;
}

.check-box-new span {
  display: inline-block;
  padding: 6px 18px;
  background-color: green;
  color: #fff;
  text-transform: capitalize;
  position: absolute;
}

.check-box-new span:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 18.5px solid transparent;
  border-left: 18px solid green;
  border-bottom: 15px solid transparent;
  position: absolute;
  right: -18px;
  top: 0;
}

/**Execution Page**/

.executionPage {
  background-color: black;
  height: 830px;

  .ant-page-header-content {
    padding-top: 12px;
    background-color: black;
  }
}

.table-setup-main-container {
  background-color: white;
}

/** Mini App **/
.tray-pop-up {
  .ant-modal-wrap {
    overflow: hidden;
    margin-top: 30px;
  }

  .ant-modal-body {
    height: 80vh;
  }

  .divider-style {
    min-height: calc(100vh - 75px);
    height: 100%;
    margin: 0;
    position: absolute;
    right: 0%;
    width: 1px;
  }

  .ant-modal-header {
    padding: 12px 24px 12px 24px;
  }

  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-image-img {
    object-fit: contain;
  }

  .ant-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }
}

// Style for text "No instruments" in all equipment listing
.listStyle {
  .ant-list-empty-text {
    padding-left: 0;
  }
}

.execution-flow-menu {
  width: 14rem;

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 15px;
  }
}

.execution-flow {
  .sidebar-header {
    position: sticky;
    z-index: 2;
    background-color: white;
    top: 0;
  }

  .ant-page-header-heading {
    align-items: center;
    border-bottom: 1px solid @theme-color-light-grey;
    box-shadow: 0 0;
  }

  .ant-page-header-heading-title {
    max-width: 100%;
    margin-right: 12px;
  }

  .ant-page-header-heading-sub-title {
    margin-top: 2px;
  }

  .items {
    color: #808285;
    font-size: 14px;
  }

  .sub-items {
    text-transform: capitalize;
  }

  .margin-top-notes {
    margin-top: 1rem;

    .ant-form-item-explain.ant-form-item-explain-error {
      text-align: left;
    }
  }

  .checklist-input .ant-form-item-control-input-content {
    display: flex;
  }

  .delete-btn-none {
    background: none !important;
    border: none;
    box-shadow: none;
  }

  .orange-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-none {
    background: none;
    box-shadow: none;
    border: none;
    width: auto;
    padding: 0;
    display: flex;

    span {
      margin-left: 10px;
      color: #f26524;
    }
  }

  .checklist-input .ant-form-item-control-input-content {
    display: flex;
  }

  .delete-btn-none {
    background: none !important;
    border: none;
    box-shadow: none;
  }

  .orange-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-none {
    background: none;
    box-shadow: none;
    border: none;
    width: auto;
    padding: 0;
    display: flex;

    span {
      margin-left: 10px;
      color: #f26524;
    }
  }

  .phase {
    padding: 7px 12px;
    background: #feefe8;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    color: #f26524;
    text-transform: capitalize;
  }

  .ant-dropdown-placement-topCenter {
    min-width: auto !important;
  }

  .ant-dropdown-placement-bottomCenter {
    min-width: auto !important;
  }

  .ant-btn {
    margin-top: 3px;
    margin-right: 4rem;
  }

  .delete-notes {
    color: rgb(242, 101, 36);
    margin-bottom: 0;
  }

  .d-none {
    display: none;
  }

  .check-box-new-preview span:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 18px solid green;
    border-bottom: 18px solid transparent;
    position: absolute;
    right: -18px;
    top: 0;
  }

  .execution-card {
    border-radius: 10px;
    text-align: center;
    border: 2px solid #d1d1d1;
  }

  .edit-execution-card {
    border-radius: 10px;
    text-align: center;
    border: 2px solid #d1d1d1;

    .ant-card-body {
      padding: 18px;
    }
  }

  .procedure-card {
    .ant-card-head-title {
      padding: 0 !important;
    }

    .ant-card-head {
      padding: 8px 8px;
    }

    .ant-card-extra {
      padding: 0 !important;
    }
  }

  .edit-record {
    background-color: white !important;
    border: 1px solid #ffbd9f;
  }

  .checklist-item {
    .remove-icon {
      position: relative;
      cursor: pointer;
      top: -9px;
      z-index: 999;
      width: 12px;
      overflow: visible;
      padding: 5px;
    }

    .bg-close {
      position: relative;
      cursor: pointer;
    }

    [ant-click-animating-without-extra-node="true"]::after {
      box-shadow: none !important;
    }
  }

  .equip-text {
    font-size: 16px;
    margin-right: 5px;
    margin-left: 5px;
    text-transform: capitalize;
    margin-top: "2px";
  }

  .steps {
    display: flex;
    align-items: center;
  }

  .divider {
    height: 19px;
    width: 1px;
    background-color: rgb(209, 209, 209);
    margin: auto;
  }

  .ant-form-item-label {
    width: 100%;
  }

  .ant-form-item-control {
    width: 100%;
  }
}

.steps {
  display: flex;
  align-items: center;
}

.dor-page-not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BrainhubCarousel__container {
  height: 100%;
}

.BrainhubCarousel {
  height: 100%;
}

.BrainhubCarousel__trackContainer {
  height: 100%;
  width: 100%;
}

.BrainhubCarousel__track {
  height: 100%;
}

.BrainhubCarouselItem {
  height: 100%;
}

.gray-color {
  background-color: #eee;
}

.box_modal {
  padding: 2em;
  background-color: white;
  border-radius: 10px;
}

.headerNameTag {
  white-space: break-spaces;
  margin: 0;
  width: 100%;
  height: 3.125rem;
}

.headerNameText {
  padding-left: 0.5em;
  padding-top: 0.9em;
  font-size: 1.2em;
}

.sidebar-versions {
  color: grey;
  position: fixed;
  bottom: 50px;
  margin-left: 17px;
  font-size: 1em;
}

.mytextdiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mytexttitle {
  flex-grow: 0;
  color: rgb(128, 130, 133);
}

.textDivider {
  flex-grow: 1;
  height: 1px;
  margin-left: 1em;
  background-color: rgb(209, 209, 209);
}

#iframe-cover {
  width: 100%;
  height: 100%;

  :nth-child(1) {
    position: unset !important;
    padding-top: unset !important;
    height: 100%;
  }
}

#dor-pop-up-carousel {
  height: "65vh";

  // padding: 20px 0;
  .ant-image {
    height: 100%;
    width: 100%;

    .ant-image-img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.fit-image {
  border: solid 0.4px rgb(209, 209, 209);
  cursor: pointer;
  border-radius: 3px;
  object-fit: contain;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.react-transform-wrapper,
.transform-component-module_wrapper__1_Fgj {
  height: 100% !important;
  width: 100% !important;
}

.react-transform-component,
.transform-component-module_content__2jYgh {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.dropdown-filter-menu {
  max-height: 22rem;
  overflow: scroll;
}

// .dor-content-table {
//   .ant-table-tbody {
//     height: 70vh;
//   }
// }

.img-loader {
  width: 100%;
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }
}

.instrument-list-image {
  height: 100%;

  .ant-spin-container {
    height: 100%;
  }
}

.ant-image-placeholder {
  .ant-spin.ant-spin-spinning {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

@theme-color-white: #ffffff;@theme-color-black: #1d1d1d;@theme-color-tangerine: #f26524;@theme-color-60p-black: #808285;@theme-color-input-border: #f0f0f0;@disabled-bg: #f5f5f5;@theme-color-light-grey: #f4f4f4;@theme-color-light-pink: #feefe8;@theme-color-mini-app-blue: #000e1a;@primary-color: @theme-color-tangerine;@border-color-base: @theme-color-input-border;@font-family: ArialMT;@disabled-color: @theme-color-black;@layout-header-background: @theme-color-white;@layout-trigger-background: @theme-color-black;@layout-sider-background: @theme-color-black;@menu-dark-bg: @theme-color-black;@menu-dark-item-active-bg: fade(@theme-color-tangerine, 20%);@table-header-color: @theme-color-60p-black;@table-row-hover-bg: @theme-color-light-pink;@table-header-bg: @theme-color-white;@notification-bg: @theme-color-black;@label-color: @theme-color-60p-black;@form-item-label-font-size: 0.8rem;@input-disabled-bg: @disabled-bg;@card-head-background: @theme-color-light-grey;@card-radius: 4px;@modal-heading-color: @theme-color-tangerine;